rl-badge {
    position: relative;
    display: block;
    width: 20px;
    min-width: 20px;
    height: 20px;

    line-height: 20px;
    text-align: center;
    font-size: 10px;

    border-radius: 100%;
    overflow-y: hidden;


    &.small {
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: 9px;
    }

    .background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--badge-background-color);
    }

    .number {
        position: relative;
        z-index: 1;
        color: var(--badge-text-color);
    }
}