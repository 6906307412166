rl-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: auto;
    height: auto;

    font-size: 19px;

    &.circle {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 18px;
        
        &.small {
            width: 19px;
            height: 19px;
            font-size: 11px;
        }
    }

    .svg-teams {
        path {
            fill: var(--general-text-color);
        }
    }
}