rl-search {
    display: block;
    position: relative;
    height: 45px;

    rl-icon[icon=search] {
        position: absolute;
        top: 0;
        padding: 13px 10px;
    }

    rl-icon[icon=search] {
        left: 0;
    }

    rl-icon[icon=close] {
        position: absolute;
        top: 0;
        right: 0;
        width: 19px;
        height: 19px;
        margin: 13px 10px;

        font-size: 14px;
        border-radius: 50%;
        color: var(--general-text-color-rgb, 0.7);
        background: var(--white-color);
    }

    input {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0 40px;

        border: none;
        border-radius: 7px;
        background: ~'rgba(var(--general-text-color-rgb), 0.07)';

        font-size: 13px;
        color: var(--general-text-color);

        -webkit-appearance: none;

        &:placeholder-shown {
            text-overflow: ellipsis;
        }
        &::placeholder {
            color: ~'rgba(var(--general-text-color-rgb), 0.4)';
        }
    }
}