.input-mixin() {
    color: var(--general-text-color);
    background: var(--background-highlight-color);
    background-clip: padding-box;

    &::placeholder {
        color: var(--general-text-color);
        opacity: .4;
    }
}

input.rl-input {
    width: 100%;
    height: 45px;
    box-sizing: border-box;

    padding: 10px;
    border-radius: var(--general-border-radius);
    border: 1px solid transparent;

    font-size: 15px;
    line-height: 1.3;

    &.ng-valid-required.ng-dirty, .ng-valid.ng-dirty {
        border: 1px solid var(--confirmation-color);
    }

    &.ng-invalid.ng-dirty {
        border: 1px solid var(--warning-color);
    }

    &:hover {
        border: 1px solid var(--general-border-color);
    }

    &:focus {
        border: 1px solid var(--general-text-inactive-color);
    }

    .input-mixin();
}


rl-rich-textarea.rl-input {
    background-color: var(--background-highlight-color);
    border-radius: var(--general-border-radius);
    border: 0;

    .fr-toolbar {
        background-color: var(--background-highlight-color);
        border-radius: var(--general-border-radius) !important;
    }

    .fr-box.fr-basic .fr-wrapper {
        background: transparent;
    }

    .fr-toolbar .fr-more-toolbar.fr-expanded {
        border-top: 1px solid var(--general-border-color);
        border-bottom: 1px solid var(--general-border-color);
    }

    .fr-toolbar .fr-command.fr-btn.fr-open {
        border: 1px solid var(--general-border-color) !important;
    }
}

select.rl-select {
    width: 100%;
    height: 45px;
    box-sizing: border-box;

    padding: 10px;
    border-radius: var(--general-border-radius);
    border: 1px solid transparent;

    font-size: 15px;
    line-height: 1.3;

    &.ng-valid-required.ng-dirty, &.ng-valid.ng-dirty {
        border: 1px solid var(--confirmation-color);
    }

    &.ng-invalid.ng-dirty {
        border: 1px solid var(--warning-color);
    }

    &:hover {
        border: 1px solid var(--general-border-color);
    }

    &:focus {
        border: 1px solid var(--general-text-inactive-color);
    }

    .input-mixin();
}

label.rl-label {
    display: inline-block;
    padding: 5px 10px;

    font-size: 11px;
    line-height: 1.3;
    font-weight: normal;
    text-transform: uppercase;

    color: var(--general-text-inactive-color);
}

.rl-field-wrapper {
    margin-bottom: 17px;

    &.horizontal {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.rl-field-group {
    padding: 10px;
    background: var(--background-highlight-color);
    border-radius: var(--general-border-radius);

    .rl-field-wrapper:last-of-type {
        margin-bottom: 0;
    }
}