header-popup {
    display: flex;
    width: 100%;
    height: 55px;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;

    background: var(--header-background-color);
    color: var(--header-text-color);

    left-buttons, right-buttons {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .buttons {
        min-width: 45px;
        height: 100%;

        button {
            width: 45px;
            min-width: 45px;
            height: 100%;
            padding: 0;
            border: none;
            background: transparent;

            color: var(--header-text-color);

            &[disabled], &.inactive {
                opacity: .5;
            }
        }
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;

        flex: 1;
        flex-direction: column;
        height: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .title-slot {
        flex: 1;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .title, .title-slot {
        h1, h2 {
            overflow: hidden;
            margin: 0;
            width: 100%;

            font-size: 17px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;

            white-space: nowrap;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        h2 {
            font-size: 13px;
            text-transform: none;
        }
    }
}