rl-select-list {
    @media @desktop-up {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;

        .icon {
            margin-right: 10px;
        }

        .radio {
            color: var(--general-text-inactive-color);
        }

        .labels-container {
            display: flex;
            flex-direction: column;
            
            .title {
                margin-right: auto;
                line-height: .9rem;
            }
            
            .help-text {
                font-size: 0.9rem;
                line-height: 0.9rem;
                font-weight: 500;
                color: var(--general-text-inactive-color);
            }
        }

        @media @desktop-up {
            cursor: pointer;
            height: 22px;
            line-height: 22px;

            rl-icon {
                margin-right: 7px;
            }

            .title {
                font-size: 13px;
            }

        }

        &.selected {
            .title {
                color: var(--highlight-color);
                font-weight: 700;
            }

            rl-icon {
                color: var(--highlight-color);
            }
        }

        .checkbox-button {
            rl-icon {
                font-size: 21px;
                color: var(--highlight-color);
            }

        }
        
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}