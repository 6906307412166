action-sheet-custom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;

    @media @desktop-up {
        margin-left: var(--sidemenu-width);

        &[inline=true] {
            position: static;
            margin-left: 0;

            .actionsheet-overlay {
                background-color: transparent;
                transition: none;
            }

            .actionsheet {
                top: 0;
                left: 0;
                transform: none;
                transition: 0.2s linear opacity;

                box-shadow: none;
                border: 1px solid var(--elevated-border-color);

                &.ng-enter, &.ng-leave-active {
                    transform: none;
                }

                > h4 {
                    margin-bottom: 0;

                    font-size: 9px;
                    text-align: left;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: var(--elevated-text-inactive-color);
                }
            }
        }
    }

    .actionsheet-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        background: rgba(0, 0, 0, 0.4);
        transition: .3s ease-in-out opacity;

        &.ng-enter, &.ng-leave-active {
            opacity: 0;
        }

        &.ng-enter-active {
            opacity: 1;
        }
    }

    .actionsheet {
        &.ng-enter, &.ng-leave-active {
            transform: translate(0, 100%);
            @media @desktop-up {
                opacity: 0;
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        display: block;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;

        margin: 0 auto;
        padding-top: 25px;
        padding-bottom: env(safe-area-inset-bottom, 0px);

        border-radius: 15px 15px 0 0;
        background: var(--elevated-background-color);
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);

        transform: translate(0, 0);
        transition: 0.2s cubic-bezier(0, 1, 0.5, 1) transform;


        @media @desktop-up {
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;

            border-radius: 15px;
            padding: var(--spacing);

            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
            transition: 0.3s linear opacity, 0.15s linear transform;
        }
        
        .sheet-close {
            position: absolute;
            top: 15px;
            right: 10px;
            
            rl-icon {
                font-size: 23px;
            }
        }

        .divider {
            position: relative;
            top: -15px;

            display: block;
            width: 42px;
            height: 5px;
            margin: 0 auto;

            border-radius: var(--general-border-radius);
            background: var(--elevated-text-color);
            opacity: 0.7;

            @media @desktop-up {
                display: none;
            }
        }

        > h4 {
            padding-bottom: var(--m-spacing);
            margin: 0 0 calc(var(--m-spacing) / 2) 0;

            @media @tablet-max {
                border-bottom: 1px solid var(--elevated-border-color);
            }

            text-align: center;
            font-weight: 600;
            font-size: 15px;
            line-height: 1.3;

            color: var(--elevated-text-color);
        }
        
        .header-subtitle {
            font-size: 0.95rem;
            font-weight: 600;
            color: var(--elevated-text-inactive-color);
            padding: 0 var(--m-spacing);
        }

        .actionsheet-content {
            padding: var(--spacing);
            display: block;

            @media @desktop-up {
                padding: var(--spacing) 0 0 0;
            }
        }

    }
}