.rl-tabs-round {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 2px;
    width: 100%;
    height: 35px;
    border-radius: 17.5px;
    background: ~'rgba(var(--general-text-color-rgb), 0.15)';
    text-align: center;
    font-size: 13px;
    line-height: 16px;

    .rl-tab {
        flex: 1;
        height: 31px;
        line-height: 31px;
        border-radius: 17.5px;
        transition: all 0.2s ease-in;

        &.active {
            font-weight: 700;
            background: var(--general-background-color);
        }
    }
}

.rl-tabs {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--general-border-color);

    &.rl-tabs-full-width {
        .rl-tab {
            flex: 1;
        }
    }

    .rl-tab {
        position: relative;
        top: 1px;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 10px;
        height: 40px;
        box-sizing: border-box;
        border-bottom: 3px solid transparent;

        cursor: pointer;
        color: rgba(var(--general-text-color-rgb), 0.4);
        font-size: 0.875rem;
        cursor: pointer;

        &.active {
            font-weight: 700;
            color: var(--highlight-color);
            border-bottom: 3px solid var(--highlight-color);
        }

        rl-badge {
            margin-left: 8px;
        }
    }
}

