translation-button {
    display: block;

    button {
        display: flex;
        align-items: center;
        padding: 0;
        color: var(--highlight-color);
        border: none;
        font-weight: 700;
        -webkit-appearance: none;
        cursor: pointer;
        height: 20px;
        line-height: 20px;

        &:hover, &:active {
            opacity: 0.9;
        }
    }

    .spinner {
        margin-left: 6.25px;
        width: 20px;
        height: 20px;
        border-color: var(--highlight-color);
        opacity: 0.8;
    }

    rl-icon {
        margin-right: 6.25px;
        font-size: 20px;
    }
}
