confirm-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    .bg {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);

        &.animated {
            animation-duration: 0.4s;
        }
    }

    .confirmation-popup {
        z-index: 2;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        min-height: 100px;
        width: 80%;
        max-width: 325px;

        text-align: center;

        background: var(--general-background-color);
        border-radius: var(--general-border-radius);
        box-shadow: 0 2px 15px rgba(0, 0, 0, .1);

        @media @desktop-up {
            max-width: 500px;
            margin-left: var(--sidemenu-width);
        }

        &.animated {
            animation-duration: 0.4s;
        }

        .content {
            padding: 25px 15px 20px 15px;
        }


        h1 {
            margin: 0;
            padding-bottom: 10px;
            color: var(--general-text-color);
        }

        p {
            margin: 0;
            max-height: 300px;
            overflow-y: auto;
            line-height: 1.4;
            overscroll-behavior: none;
            -webkit-overflow-scrolling: touch;
            color: var(--general-text-color);
        }

        footer {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid var(--general-border-color);

            rl-button {
                width: 50%;
            }

            rl-button.no {
                button {
                    color: var(--general-text-color);
                    opacity: 0.4;
                }
            }
        }

        &.alert {
            footer {
                justify-content: center;
            }
        }
    }
}