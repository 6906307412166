.rl-new-form {

    .overflow-mixin() {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        display: inline-flex;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing);
    padding: var(--spacing);

    .form-item {
        display: flex;
        flex-direction: column;
    }

    .form-item label {
        font-size: 0.9rem;
        font-weight: 600;
        padding: 0 var(--m-spacing) var(--s-spacing);

        &.required::after {
            content: '*';
            color: var(--highlight-color); 
        }
    }

    .form-item:has(.required-field-empty) {
        .alert-border-style() {
            border: 1px solid var(--alert-color);
        }

        .text-field {
            .alert-border-style();
        }

        rl-phone-input {
            .select-area {
                .alert-border-style();
            }

            rl-text-input input {
                .alert-border-style();
            }
        }

        autocomplete input{
            .alert-border-style();
        }

        rl-select {
            .select-area {
                .alert-border-style();
            }
        }
    }

    .form-item:has(.error-message) {
        .warning-border-style() {
            border: 1px solid var(--warning-color);
        }

        .text-field {
            .warning-border-style();
        }

        rl-phone-input {
            .select-area {
                .warning-border-style();
            }

            rl-text-input input {
                .warning-border-style();
            }
        }

        autocomplete input{
            .warning-border-style();
        }

        rl-select {
            .select-area {
                .warning-border-style();
            }
        }
    }

    .text-field {
        .overflow-mixin();

        position: relative;
        flex: 1;
        padding: 0 var(--m-spacing);
        max-height: 45px;
        line-height: 45px;

        font-size: 1rem;
        color: var(--general-text-color);
        background-color: var(--background-highlight-color);
        border-radius: var(--general-border-radius);
        border: 1px solid var(--background-highlight-color);

        &:focus-within {
            border: 1px solid var(--highlight-color);
        }

        & input {
            background-color: inherit;
            border: none;
            color: inherit;
            flex-basis: 100%;
            line-height: inherit;
            .overflow-mixin();
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            cursor: inherit;
        }
        
        &.disabled {
            background-color: var(--locked-field-color);
            color: var(--general-text-inactive-color);
            border: none;
            cursor: not-allowed;
            
            input {
                background: unset;
                color: unset;
                //fixes user agent styles for ios devices
                opacity: 1;
            }
        }
    }

    rl-select {
        .select-area.opened {
            border: 1px solid var(--highlight-color) !important;
        }
    }

    rl-phone-input {
        rl-text-input input:focus {
            border: 1px solid var(--highlight-color) !important;
        }
    }

    .select-field {
        position: relative;

        rl-select {     
            &[disabled] {
                background: unset;
                
                .select-area {
                    color: var(--general-text-inactive-color);
                    background-color: var(--locked-field-color);
                    cursor: not-allowed;
                    border: none;

                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .field-postfix {
        color: var(--general-text-inactive-color);
    }

    .toggle-field {
        .text-field();
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:focus-within {
            border: 1px solid transparent;
        }

        rl-toggle {
            display: flex;
            align-items: center;

            label {
                padding: 0;
            }

            input[type=checkbox]:checked + label {
                background-color: var(--highlight-color);
            }
        }

        label {
            padding: 0;
            display: flex;
            align-items: center;
            gap: var(--s-spacing);
            font-weight: 400;
            font-size: 1rem;
        }
    }

    .search-wrapper {
        input:disabled {
            background: inherit;
            cursor: not-allowed;
            border: none;
            color: var(--general-text-inactive-color);
        }
        rl-icon {
            display: none;
        }
    }
}

html[dir="rtl"] .rl-new-form {
    rl-phone-input {
        rl-select .select-area {
            padding: 0 10px 0 30px;
        }
    }
}