action-popup {
    .modal-close-icon {
        cursor: pointer;
        width: 20px;
        margin-left: auto;
    }
    
    .action-popup-wrapper {
        position: fixed;
        z-index: 201;

        &.show {
            .action-popup-bg {
                display: block;
            }

            .action-popup {
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;

                @media @desktop-up {
                    margin-left: calc(var(--sidemenu-width) / 2);
                }
            }
        }
    }

    .action-popup-bg {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        display: none;
    }

    .action-popup {
        z-index: 15;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.1);

        border-radius: 7px;
        background: var(--elevated-background-color);
        box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);

        transition: 0.3s linear opacity, 0.15s linear transform;
        opacity: 0;
    }
}