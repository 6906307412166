rl-accordion-item {
    display: flex;
    flex-direction: column;
    padding: var(--m-spacing) var(--spacing);
    gap: calc(var(--m-spacing) * 2);

    border-radius: var(--general-border-radius);
    box-shadow: 0 7px 15px 0 var(--shadow-color);
    background: var(--general-background-color);

    &.opening {
        .accordion-item-body {
            display: block;
            opacity: 0;
        }
    }

    &.opened {
        [icon=arrow-top] {
            display: inline-flex;
        }

        .accordion-item-body {
            display: block;
            opacity: 1;
        }
    }

    &:not(.opened) {
        [icon=arrow-bottom] {
            display: inline-flex;
        }
    }

    .accordion-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        [ng-transclude] {
            flex: 1;
        }

        rl-icon {
            display: none;
            background: var(--general-border-color);
            box-shadow: 0 7px 15px 0 var(--shadow-color);
        }
    }

    .accordion-item-body {
        display: none;
        transition: opacity 0.3s ease-in-out;
    }
}
