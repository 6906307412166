empty-search-results {
    .help-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 90px auto 0;
        max-width: 250px;
        text-align: center;

        rl-icon {
            position: relative;
            font-size: 56px;
            margin-bottom: 20px;
            color: var(--general-text-inactive-color);
        }

        h4 {
            margin: 0;
            font-size: 15px;
            font-weight: 700;
        }

        p {
            margin: 0;
            font-size: 13px;
        }

        @media @desktop-up {
            max-width: 320px;

            h4 {
                margin-bottom: 10px;
            }

            rl-icon {
                margin-bottom: 25px;
            }
        }
    }
}