rl-button {
    button {
        min-width: 150px;
        height: 45px;
        padding: 0 10px;

        font-size: 15px;
        font-weight: 600;

        -webkit-appearance: none;
        background: none;
        border: none;
        border-radius: var(--general-border-radius);

        opacity: 1;
        transition: opacity 0.3s ease;

        &:active:not([disabled]), &:hover:not([disabled]) {
            opacity: 0.9;
        }

        &.round {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0;
            width: 50px;
            height: 50px;
            min-width: 50px;
            border-radius: 50%;
        }

        &.standard {
            color: var(--button-text-color);
            background: var(--button-background-color);

            &.secondary, &[disabled] {
                background: rgba(var(--general-text-color-rgb), .25);
                color: rgba(var(--general-text-color-rgb), .65);
            }
        }

        &.outline {
            color: var(--general-text-color);
            border: 1px solid currentColor;

            &.secondary, &[disabled] {
                opacity: 0.5;
            }
        }

        &.highlight {
            color: var(--highlight-color);
        }

        &.transparent {
            color: var(--highlight-color);
            border: none;

            &.secondary, &[disabled] {
                color: var(--general-text-color);
                opacity: 0.5;
            }
        }


        &.full-width {
            width: 100%;
    
            button {
                width: 100%;
            }
        }

        &.small {
            height: 40px;
            padding: 0 5px;
            min-width: 100px;
            font-weight: 500;
        }

        &.link {
            height: auto;
            min-width: auto;
            padding: 0;
        }

        &.upper {
            text-transform: uppercase;
        }

        rl-icon {
            &.left {
                margin-right: 5px;
            }

            &.right {
                margin-left: 5px;
            }
        }
    }
}