prev-next-buttons {
    display: inline-flex;
    filter: drop-shadow(0 6px 15px var(--shadow-color));
    background: var(--elevated-background-color);
    border-radius: var(--general-border-radius);

    button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 46px;
        height: 46px;
        padding: 0;

        color: var(--elevated-text-color);
        background: var(--elevated-background-color);
        border-radius: var(--general-border-radius);
        border: 1px solid var(--elevated-border-color);

        &:not([disabled]) {
            cursor: pointer;
        }

        &[disabled] {
            color: var(--elevated-text-inactive-color);
        }

        &.prev {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.next, &.submit:not(.submit-only) {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.submit {
            width: auto;
            padding: 0 25px;
            font-weight: bold;
            background: var(--highlight-color);
            color: var(--text-on-highlight-color);

            &[disabled] {
                background: var(--elevated-background-color);
                color: var(--elevated-text-inactive-color);
            }
        }

        &.btn-close {
            width: auto;
            padding: 0 25px;
            font-weight: bold;
        }
    }

    &.no-steps {
        button.submit {
            border-top-left-radius: var(--general-border-radius);
            border-bottom-left-radius: var(--general-border-radius);
        }
    }
}