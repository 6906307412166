approve-slider {
    display: block;
    position: relative;
    max-width: 345px;
    width: 100%;

    .line {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 34px;

        border-radius: 20px;
        background-color: ~'rgba(var(--general-text-color-rgb), 0.3)';
        transition: background-color 0.3s ease-in;

        &.approved {
            background-color: var(--confirmation-color);

            .swiper {
                color: var(--confirmation-color);
                box-shadow: 0 5px 7px ~'rgba(var(--confirmation-color-rgb), .1)';
            }

            .approved-text {
                right: 50px;
            }
        }

        &.rejected {
            background-color: var(--warning-color);

            .swiper {
                color: var(--warning-color);
                box-shadow: 0 5px 7px ~'rgba(var(--warning-color-rgb), .1)';
            }

            .rejected-text {
                left: 50px;
            }
        }
    }

    .swiper {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 44px;
        height: 44px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 5px 7px ~'rgba(var(--general-text-color-rgb), .1)';

        transform: translateX(0);
        
        rl-icon {
            font-size: 28px;
        }
    }

    .rejected-text, .approved-text {
        position: absolute;

        color: var(--white-color);
        font-size: 14px;
        text-transform: uppercase;
        transition-duration: 0.1s;
    }

    .rejected-text {
        left: 15px;
    }

    .approved-text {
        right: 15px;
    }
}