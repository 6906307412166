rl-date-filter {
    position: relative;
    display: inline-block;
    height: 40px;

    .open-filter {
        padding: 10px;

        font-size: 13px;
        border-radius: var(--general-border-radius);
        background-color: var(--general-background-color);
        line-height: 20px;
        cursor: pointer;

        &.selected {
            background-color: var(--highlight-color);
            color: var(--text-on-highlight-color);

            rl-icon {
                color: var(--text-on-highlight-color);
            }
        }

        rl-icon {
            font-size: 12px;
            color: var(--general-text-inactive-color);
        }
    }
}

rl-date-filters-popup {
    action-sheet-custom .actionsheet {

        @media @desktop-up {
            width: 320px;
            margin-top: 50px;
            padding: 10px;
        }

        .actionsheet-content {
            padding-top: 0;
        }
    }

    @media @desktop-up {
        rl-select-list {
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 10px;

            .item {
                flex-direction: row-reverse;
                justify-content: flex-end;
                flex-basis: 50%;
            }
        }
    }

    .custom-date {
        @media @desktop-up {
            margin-top: 7px;
        }

        .date-range-wrapper {
            margin-bottom: 20px;
        }

        label {
            padding: 7px 7px 3px 0;
            font-size: 9px;
            text-transform: uppercase;
            color: var(--general-text-inactive-color);
        }

        .date-range {
            display: flex;
            justify-content: space-between;
            overflow: hidden;

            border-radius: var(--general-border-radius);
            border: 1px solid var(--general-border-color);
            background-color: var(--background-highlight-color);

            input {
                position: relative;
                height: 32px;
                padding: 0 10px;
                width: calc(50% - 35px / 2);

                font-size: 13px;
                color: var(--background-highlight-color);
                border: none;
                background-color: transparent;
                --webkit-appearence: none;
                box-sizing: border-box;

                &:focus, &:valid {
                    color: var(--general-text-color);
                }

                &:focus:before, &:valid:before {
                    display: none;
                }


                &:before {
                    color: var(--general-text-inactive-color);
                    content: attr(placeholder);
                    position: absolute;
                    top: 7px;

                    @media @mobile-max {
                        width: 150px;
                    }
                }
            }

            rl-icon {
                padding: 0 10px;
                align-self: center;
                font-size: 12px;
                color: var(--general-text-inactive-color);
            }
        }


        .apply button {
            font-weight: 400;

            @media @desktop-up {
                height: 36px;
                font-size: 12px;
            }
        }
    }

    .clear button {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;

        font-weight: 400;
        color: var(--warning-color);

        @media @desktop-up {
            height: 40px;
            font-size: 12px;
            rl-icon {
                font-size: 14px;
            }
        }

        rl-icon {
            margin-right: 7px;
            font-size: 14px;
        }
    }
}