rl-search-result-item {
    display: block;
    margin: 10px 15px;
    padding: 10px;
    border-radius: var(--general-border-radius);
    background-color: var(--general-background-color);

    @media @desktop-up {
        margin: 15px;
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }

    .image {
        position: relative;
        width: 70px;
        height: 70px;
        margin-right: 10px;
        border-radius: var(--general-border-radius);

        @media @desktop-up {
            width: 90px;
            height: 90px;
            margin-right: 15px;
        }

        file-preview {
            width: 100%;
            height: 100%;
            border-radius: var(--general-border-radius);
            overflow: hidden;

            .background:not(.image):after {
                border-radius: var(--general-border-radius);
            }

            .background.no-preview:after {
                display: none;
            }

            .file-icon[icon="play"] {
                width: 40px;
                height: 40px;
            }
        }

        > rl-icon {
            color: var(--general-text-inactive-color);
        }

        &:not(.has-media) {
            background-color: var(--background-highlight-color);

            > rl-icon:not([icon=poll-people]) {
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);

                font-size: 35px;
            }
        }

        &.has-media {
            > rl-icon {
                position: absolute;
                z-index: 2;
                bottom: -5px;
                right: -5px;

                border-radius: 50%;
                width: 24px;
                height: 24px;
                font-size: 13px;
                border: 1px solid var(--general-border-color);
                background-color: var(--background-highlight-color);

                @media @desktop-up {
                    width: 32px;
                    height: 32px;
                    bottom: -6px;
                    right: -6px;
                    font-size: 16px;
                }
            }
        }

        &.has-poll {
            [icon=poll-people] {
                position: absolute;
                z-index: 2;
                bottom: -5px;
                right: -5px;

                border-radius: 50%;
                width: 24px;
                height: 24px;
                font-size: 13px;
                border: 1px solid var(--general-border-color);
                background-color: var(--background-highlight-color);

                @media @desktop-up {
                    width: 32px;
                    height: 32px;
                    bottom: -6px;
                    right: -6px;
                }
            }
        }
    }

    .content-wrapper {
        flex: 1;
        overflow: hidden;
        margin-bottom: auto;

        .item-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            h4 {
                margin: 0 5px 0 0;
                display: flex;
                overflow: hidden;
                font-size: 13px;
                font-weight: 700;

                bdi:last-of-type {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    width: 100%;
                    white-space: nowrap;
                }
            }

            .module-label {
                padding: 5px;

                font-size: 10px;
                font-weight: 700;

                @media @desktop-up {
                    font-size: 11px;
                    padding: 7px 10px;
                }

                border-radius: var(--general-border-radius);
                background-color: var(--highlight-color);
                color: var(--text-on-highlight-color);
            }
        }

        .meta {
            display: flex;
            white-space: nowrap;
            height: 17px;
            margin-bottom: 5px;

            .name {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                font-size: 13px;

                &:nth-of-type(2)::before {
                    content: '·';
                    font-weight: bold;
                    padding: 0 5px;
                }
            }
        }

        .content {
            margin: 0;

            .teaser {
                display: none;
            }

            @media @mobile-max {
                .teaser.short {
                    display: inline-block;
                }
            }
            @media @tablet-up {
                .teaser:not(.short) {
                    display: inline-block;
                }
            }


            em {
                font-weight: 700;
                font-style: normal;
            }
        }

        &.catalog-category {
            .item-title {
                align-items: flex-start;
            }

            h4 {
                overflow: hidden;
                margin: 30px 5px 0 0;

                @media @desktop-up {
                    margin: 40px 5px 0 0;
                }

                bdi:first-of-type {
                    font-weight: 400;
                }

                bdi:last-of-type {
                    font-weight: bold;
                    margin: 0 5px 0 5px;
                }
            }
        }
    }

    .module-name {
        border-top: 1px solid var(--general-border-color);
        padding-top: 8px;

        .breadcrumbs-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: nowrap;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                position: relative;
                overflow: hidden;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:not(:last-of-type) {
                    padding-right: 25px;
                    max-width: 50%;

                    rl-icon {
                        display: inline-flex;
                    }
                }

                &:after {
                    content: '\0000a0';
                    display: inline-block;
                    width: 1px;
                }

                rl-icon {
                    display: none;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    padding: 0 10px;
                    transform: translateY(-50%);
                    font-size: 10px;
                }
            }
        }
    }
}