.input-mix() {
color: var(--general-text-color);
    background: var(--general-background-color);
    background-clip: padding-box;

    &::-webkit-input-placeholder {
        color: var(--general-text-color);
        opacity: .6;
    }
}

.rl-form {
    &.show-validation {
        multiple-permission-selector-wrapper.ng-invalid {
            .permissions-label-wrapper, .permissions-set {
                border-color: var(--alert-color);
            }

            .permission-selector-label {
                background: var(--alert-color);
            }
        }

        task-assignees.ng-invalid {
            .assignees-label {
                background: var(--alert-color);
            }
        }

        .rl-field.ng-invalid, .rl-field-group.ng-invalid  {
            > label {
                background: var(--alert-color);
            }

            > .rl-input {
                border-color: var(--alert-color);
            }

            > .rl-validation-message {
                display: block;
                padding: 10px 10px 0 10px;
                font-size: 13px;
                background: var(--general-background-color);
                color: var(--alert-color);

                &:before {
                    content: '*';
                }
            }
        }
    }

    .rl-field {
        display: flex;
        flex-direction: column;
    }

    .hint {
        display: block;
        padding: 6px 10px;
        opacity: 0.4;
        font-size: 13px;
    }

    .rl-field-group {
        display: flex;
        flex-direction: column;
        padding: 0;

        .rl-input {
            &:not(:last-of-type) {
                border-bottom-color: var(--general-background-color);
            }
        }
    }

    .rl-field, .rl-field-group, .permission-selector-controls {
        > label {
            padding: 15px 10px 7px;
            font-size: 15px;
            font-weight: 500;
        }

        > .sub-label {
            padding: 7px 10px 10px;
            font-size: 13px;
            color: rgba(var(--general-text-color-rgb), 0.4);
        }
    }

    .rl-field, .rl-field-group {
        &.bottom-space {
            margin-bottom: 15px;
        }

        &.top-space {
            margin-top: 15px;
        }
    }

    .rl-input {
        position: relative;
        display: flex;

        border-top: 1px solid var(--general-border-color);
        border-bottom: 1px solid var(--general-border-color);

        &.disabled {
            opacity: 0.5;
        }

        > rl-icon, .prefix > rl-icon {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;

            display: inline-block;
            height: 50px;
            min-width: 40px;

            line-height: 50px;
            font-size: 17px;
            text-align: center;
        }

        rl-icon + input, rl-icon + select, rl-icon + label {
            padding-left: 40px;
        }

        &.time, &.date-time {
            display: flex;

            .prefix {
                display: flex;
                align-items: center;
                box-sizing: border-box;

                height: 50px;

                padding-right: 10px;

                .input-mix();
            }

            input {
                flex: 1;

                padding: 0 10px 0 0;

                &.ng-invalid:not(.ng-untouched) {
                    border: 1px solid var(--alert-color);
                }
            }

            rl-icon + label {
                padding: 0;
            }

            rl-icon {
                position: static;
            }
        }

        &.number {
            display: flex;

            input {
                width: 70px;
                padding-right: 0;

                @media @desktop-up {
                    width: 80px;
                }
            }

            .postfix {
                flex: 1;
                display: flex;
                align-items: center;

                color: var(----general-text-color);
                background: var(--general-background-color);
            }
        }

        &.toggle {
            justify-content: space-between;
            align-items: center;

            label {
                flex: 1;
            }

            rl-icon + label {
                padding-left: 30px;
            }
        }
    }

    input, select, .rl-input.toggle {
        width: 100%;
        height: 50px;
        padding: 0 10px;
        box-sizing: border-box;

        line-height: 17px;
        font-size: 15px;

        -webkit-appearance: none;

        .input-mix()
    }

    textarea {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;

        .input-mix()
    }

    input, select {
        border-radius: 0;
        border: none;
    }

    permission-selector,
    multiple-permission-selector,
    multiple-permission-selector-wrapper {
        #permissions {
            display: flex;
            padding: 8px 10px 0 0;

            font-size: 15px;

            border-top: 1px solid rgba(var(--general-text-color-rgb), .1);
            border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);

            .input-mix();
        }

        .click-area {
            &:before {
                content: "\f007"; // fa-user

                display: inline-block;
                height: 30px;
                min-width: 40px;

                font-family: "Font Awesome 6\ Pro";
                font-size: 17px;
                line-height: 30px;
                font-weight: 300;
                text-align: center;
            }
        }
    }

    multiple-permission-selector,
    multiple-permission-selector-wrapper {
        #permissions {
            border-top: unset;
            border-bottom: unset;
        }
    }

    .arrow {
        &:after {
            content: '';
            position: absolute;
            right: 12px;
            top: 50%;
            display: block;
            width: 10px;
            height: 10px;
            margin-top: -5px;
            transform: rotate(-45deg);
            border: solid var(--general-text-color);
            border-width: 0 2px 2px 0;
            opacity: .5;
        }
    }

    multiple-permission-selector-wrapper .permissions-set {
        border-bottom: 1px solid rgba(var(--general-text-color-rgb), .1);
    }
}