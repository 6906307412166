prompt-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    .bg {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);

        &.animated {
            animation-duration: 0.4s;
        }
    }

    .prompt-popup {
        z-index: 2;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100px;
        width: 80%;
        max-width: 325px;

        text-align: center;

        background: var(--white-color);
        border-radius: var(--general-border-radius);
        box-shadow: 0 2px 15px rgba(0, 0, 0, .1);

        @media @desktop-up {
            transform: translateX(calc(var(--sidemenu-width) / 2));
        }

        &.animated {
            animation-duration: 0.4s;
        }

        .content {
            padding: 30px 15px 0 15px;
            position: relative;
        }

        rl-icon {
            position: absolute;
            width: 43px;
            height: 43px;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);

            font-size: 26px;
            background: var(--highlight-color);
            color: var(--text-on-highlight-color);
        }


        h1 {
            margin: 0;
            font-size: 15px;
            padding-bottom: 24px;
        }

        textarea {
            width: 100%;
            height: 115px;
            box-sizing: border-box;
            padding: 10px;

            border: none;
            -webkit-appearance: none;

            background: var(--background-highlight-color);

            &::placeholder {
                color: ~'rgba(var(--general-text-color-rgb), 0.4)';
            }
        }

        rl-button {
            display: block;
            padding: 15px;
        }
    }
}