.spinner {
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    text-indent: -9999px;
    width: 24px;
    height: 24px;

    border: solid 2px var(--general-text-color);
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    border-radius: 50%;

    animation: spinner 400ms linear infinite;

    &.ng-animate {
        animation: none 0s;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.placeholder-shimmer {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: ~"rgba(var(--general-text-color-rgb), 0.15)";
    background: ~"linear-gradient(to right, rgba(var(--general-text-color-rgb), 0.15) 4%, rgba(var(--general-text-color-rgb), 0.25) 25%, rgba(var(--general-text-color-rgb), 0.15) 36%)";
    background-size: 80vw 100%;

    &.text-shimmer {
        width: 200px;
        height: 1.3rem;
        border-radius: var(--general-border-radius);

        &.short-shimmer {
            width: 50px;
        }

        &.long-shimmer {
            width: 300px;
        }
    }

    &.circle-shimmer {
        border-radius: 50%;
    }
}


@keyframes placeHolderShimmer {
    0% {
        background-position: -80vw 0
    }

    100% {
        background-position: 80vw 0
    }
}