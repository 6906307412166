action-sheet {
    z-index: 900;
    transition-duration: .15s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    overscroll-behavior: none;

    .actionsheet-group {
        max-height: var(--max-mobile-sheet-height);
        overflow: auto;
        
        @media @desktop-up {
            max-height: var(--max-desktop-sheet-height);
        }
    }
    
    .reaction {
        background: none !important;
        box-shadow: none !important;
    }
    
    reaction-icon, reaction-icon img {
        width: 100% !important;
        height: 100% !important
    }
    
    reaction-icon rl-icon span {
        font-size: 14px !important; 
    }

    &:not(.inline) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &:not(.inline):not(.attached) {
        background: rgba(0, 0, 0, 0.4);
    }

    &.ng-enter, &.ng-leave-active {
        opacity: 0;

        .actionsheet {
            transform: translate3d(0, 100%, 0);
        }
    }

    &.ng-enter-active {
        opacity: 1;
    }

    .actionsheet-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &.attached {
        .actionsheet {
            top: 10px;
            right: 10px;
            bottom: auto;
            left: auto;
            border-radius: var(--general-border-radius);
            border: 1px solid var(--general-background-color);
            box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }

        &.ng-enter, &.ng-leave-active {
            .actionsheet {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.attached, &.inline {
        .actionsheet-group {
            .actionsheet-description {
                font-size: 11px;
                font-weight: 400;
                color: var(--general-text-color);
                padding: 10px;
                padding-bottom: 5px;
                text-transform: uppercase;
                opacity: 0.6;
            }

            .actionsheet-button,
            .actionsheet-label {
                padding: 0 10px;
            }
        }
    }

    &:not(.attached):not(.inline) {
        .actionsheet {
            bottom: 0;
            left: 0;
            right: 0;

            width: 100%;
            padding-top: 25px;
            padding-bottom: env(safe-area-inset-bottom, 0px);

            border-radius: 15px 15px 0 0;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

            .divider {
                position: relative;
                display: block;
                top: -15px;
                width: 42px;
                height: 5px;
                margin: 0 auto;
                border-radius: 7px;
                background: var(--text-on-white-color);
                opacity: 0.7;
            }

            .actionsheet-group {
                .actionsheet-description {
                    display: none;
                }
                .actionsheet-button,
                .actionsheet-label {
                    padding: 0 15px;
                }
            }

            .actionsheet-header {
                text-align: center;
                padding: 10px;
                border-bottom: 1px solid #ebecee;
                white-space: nowrap;

                .actionsheet-image {
                    display: inline-block;
                    position: relative;
                    margin-bottom: 4px;
                }
                
                .actionsheet-description {
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 75vw;
                    margin: 0 auto;
                }

                img {
                    width: 57px;
                    height: 57px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-bottom: 5px;
                }

                .head-icon {
                    width: 57px;
                    color: #3c4858;

                    svg {
                        padding: 1px;
                        box-sizing: border-box;
                        border-radius: 50%;

                        path {
                            fill: #8893a3;
                        }
                    }
                }

                .badge-icon {
                    position: absolute;
                    line-height: 14px;
                    right: -3px;
                    bottom: -1px;
                    top: auto;
                    background: #3c4858;
                    color: white;
                    border-radius: 50%;
                    width: 27px;
                    height: 27px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, .15);

                    rl-icon {
                        font-size: 14px;
                    }

                    &.socialPost {
                        background: #eb6262;
                    }

                    &.contentItem {
                        background: #5fa3f1;
                    }

                    &.serviceFormFilling {
                        background: #3c4858;
                    }

                    icon {
                        width: 14px;
                        height: 14px;
                        color: #fff;

                        svg {
                            stroke-width: 0;
                            border-radius: 0;
                            padding: 0;

                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .actionsheet {
        display: block;
        position: absolute;
        z-index: 2;

        background: var(--white-color);
        transform: translate3d(0, 0, 0);


        .actionsheet-group {
            border-bottom: 1px solid #ddd;

            &:last-child {
                border: none;
            }

            .actionsheet-button,
            .actionsheet-label {
                display: flex;
                width: 100%;
                height: 40px;
                margin: 0;
                align-items: center;
                overflow: hidden;
                position: relative;
                text-align: left;
                color: unset;
                border: none;
                background: none;
                box-sizing: border-box;
                text-decoration: none;

                rl-icon {
                    margin-right: 10px;
                    margin-top: -3px;
                    color: var(--text-on-white-color);

                    &.right-icon {
                        margin-right: 0;
                        padding-left: 10px;
                        margin-left: auto;
                    }

                    &.active {
                        color: var(--highlight-color);

                        &.label-color + .text-wrapper span {
                            color: var(--highlight-color);
                        }
                    }

                    &.red {
                        color: var(--warning-color);

                        &.label-color + .text-wrapper span {
                            color: var(--warning-color);
                        }
                    }

                    &.blue {
                        color: var(--highlight-color);

                        &.label-color + .text-wrapper span {
                            color: var(--highlight-color);
                        }
                    }

                    &.green {
                        color: var(--confirmation-color);

                        &.label-color + .text-wrapper span {
                            color: var(--confirmation-color);
                        }
                    }

                    &.orange {
                        color: var(--quiz-color);

                        &.label-color + .text-wrapper span {
                            color: var(--quiz-color);
                        }
                    }

                    &.purple {
                        color: var(--service-form-color);

                        &.label-color + .text-wrapper span {
                            color: var(--service-form-color);
                        }
                    }
                    
                    &.red-dot::before {
                        content: '';
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background-color: var(--warning-color);
                        top: 8px; 
                        left: 30px;
                        border: 1px solid white;
                    }
                }

                icon {
                    margin-right: 10px;
                    color: unset;

                    &.red {
                        &.label-color + .text-wrapper span {
                            color: var(--warning-color);
                        }
                    }
                    
                    &.red-dot {
                        position: relative;
                        display: inline-block;
                    }

                    svg {
                        stroke-width: 0;
                        max-width: 18px;
                    }

                    &.icon-shape {
                        svg {
                            width: 20px;
                            max-width: 20px;
                        }

                        &.big {
                            svg {
                                width: 26px;
                                max-width: 26px;
                            }
                        }
                    }
                }

                .text-wrapper {
                    display: flex;
                    flex-direction: column;
                    color: var(--text-on-white-color);
                }

                .text {
                    display: block;
                }
            }

            .actionsheet-label {
                display: flex;
                flex-direction: column;
                padding-top: 14px;
                padding-bottom: 6px;
                min-height: 1px;
                color: #aaa;
                line-height: 16px;
            }

            .actionsheet-button.actionsheet-button-disabled {
                opacity: .5;
            }
        }
    }
}

.action-disable-scroll {
    overflow: hidden;
}