rl-select {
    display: block;
    position: relative;
    min-height: 45px;
    border-radius: var(--general-border-radius);
    background: var(--background-highlight-color);


    .select-area {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 45px;
        box-sizing: border-box;

        padding: 0 30px 0 10px;
        border-radius: var(--general-border-radius);
        border: 1px solid transparent;

        font-size: 15px;
        line-height: 1.3;
        cursor: pointer;

        > icon, > img {
            width: 26px;
            height: 26px;
            display: block;
        }

        > rl-icon, > icon, > img, activity-indicator {
            & + span {
                margin-left: 10px;
            }
        }

        > span {
            margin-right: auto;
            margin-left: 0;
            line-height: 1.3;

            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.opened {
            border: 1px solid var(--general-text-inactive-color) !important;
        }

        &.disabled {
            cursor: not-allowed;
            > span {
                opacity: .5;
            }

            &:after {
                display: none;
            }
        }

        &:hover {
            border: 1px solid var(--general-border-color);
        }

        color: var(--general-text-color);
        background: var(--background-highlight-color);
        background-clip: padding-box;

        &.placeholder {
            color: rgba(var(--general-text-color-rgb), .4);
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: var(--general-text-inactive-color) transparent transparent transparent;
        }

        .lock {
            position: absolute;
            right: var(--m-spacing);
            color: var(--general-text-inactive-color);
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    @media @tablet-max {
        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;

            background: rgba(0, 0, 0, 0.4);
        }
    }

    .options {
        position: fixed;
        z-index: 2;
        display: flex;
        flex-direction: column;
        width: 100%;

        @media @tablet-max {
            max-height: 40vh;
            z-index: 201;
            bottom: 0;
            left: 0;
            padding-top: 15px;

            padding-bottom: 15px;
            padding-bottom: calc(15px + env(safe-area-inset-bottom));

            &:after {
                content: '';
                display: block;
                position: absolute;

                left: 50%;
                transform: translateX(-50%);
                top: 10px;
                width: 42px;
                height: 5px;

                background: var(--text-on-white-color);
                opacity: 0.3;
            }

            &.ng-hide, &.ng-hide-add-active {
                transform: translate(0, 100%);
            }

            transform: translate(0, 0);
            transition: 0.2s cubic-bezier(0, 1, 0.5, 1) transform;
        }

        border: 1px solid var(--general-border-color);
        background: var(--white-color);
        border-radius: 15px 15px 0 0;
        box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);

        @media @desktop-up {
            position: absolute;
            max-height: 400px;

            top: calc(100% + 5px);

            &.top {
                top: auto;
                bottom: calc(100% + 5px);
            }

            border-radius: var(--general-border-radius);
            box-shadow: 0 6px 30px rgb(0 0 0 / 15%);

            transition: 0.15s linear opacity;

            &.ng-enter {
                opacity: 0;
            }

            &.ng-enter-active {
                opacity: 1;
            }

            &.ng-leave {
                opacity: 1;
            }

            &.ng-leave.ng-leave-active {
                opacity: 0;
            }

            &.ng-hide {
                opacity: 0;
            }
        }

        .scroll-container {
            flex: 1;
            overflow-y: auto;
            overscroll-behavior-y: contain;
        }

        .option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 45px;
            padding: 0 15px;
            cursor: pointer;

            &.selected {
                rl-icon {
                    color: var(--highlight-color);
                }

                > span {
                    color: var(--highlight-color);
                    font-weight: bold;
                }

                .radio {
                    position: relative;
                    border: 2px solid var(--highlight-color);

                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);

                        width: 8px;
                        height: 8px;

                        background: var(--highlight-color);
                        border-radius: 50%;
                    }
                }
            }

            > icon, > img {
                width: 26px;
                height: 26px;
                display: block;
            }

            > rl-icon, > icon, > img, activity-indicator {
                & + span {
                    margin-left: 10px;
                }
            }

            > span {
                margin-right: auto;
                margin-left: 0;

                color: var(--text-on-white-color);

                overflow: hidden;
                text-overflow: ellipsis;
            }

            .radio {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                flex: 0 0 auto;

                border: 2px solid var(--general-border-color);
            }

            &:hover {
                background: var(--background-highlight-color);
            }
        }
    }
}