rl-textarea {
    display: flex;
    width: 100%;
    position: relative;

    textarea {
        width: 100%;
        min-height: 120px;
        padding: 10px;
        box-sizing: border-box;

        font-size: 15px;
        line-height: 1.3;

        background: var(--background-highlight-color);
        color: var(--general-text-color);
        -webkit-appearance: none;
        resize: none;

        border: 1px solid transparent;
        border-radius: 7px;

        &.validate {
            &.ng-valid.ng-dirty {
                border: 1px solid var(--confirmation-color);
            }

            &.ng-invalid.ng-dirty {
                border: 1px solid var(--warning-color);
            }
        }

        &:hover {
            border: 1px solid var(--general-border-color);
        }

        &:focus {
            border: 1px solid var(--general-text-inactive-color);
        }

        &:disabled {
            background: ~'rgba(var(--background-highlight-color-rgb), 0.5)';
            color: ~'rgba(var(--general-text-color), 0.5)';
            cursor: not-allowed;
        }

        &::placeholder {
            color: var(--general-text-color);
            opacity: .4;
        }
    }

    span {
        position: absolute;
        bottom: 10px;
        right: 10px;

        opacity: .4;
        font-size: 10px;
    }
}