rl-loading-placeholder {
    .placeholder-shimmer {
        display: block;
        height: 30px;
        min-width: 10px;
        border-radius: 4px;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }

        &.no-animation {
            animation: none;
            background-color: ~"rgba(var(--general-text-color-rgb), 0.15)";
            background-image: none;
        }
    }

    &.invert .placeholder-shimmer {
        background-image: ~"linear-gradient(to right, rgba(var(--general-text-color-rgb), 0.25) 4%, rgba(var(--general-text-color-rgb), 0.15) 25%, rgba(var(--general-text-color-rgb), 0.25) 36%)";

        &.no-animation {
            animation: none;
            background-color: ~"rgba(var(--general-text-color-rgb), 0.25)";
            background-image: none;
        }
    }
}