rl-text-input {
    display: flex;
    width: 100%;
    position: relative;

    input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        padding: 0 10px;
        line-height: 45px;

        font-size: 15px;

        background: var(--background-highlight-color);
        color: var(--general-text-color);
        
        border: 1px solid transparent;
        border-radius: var(--general-border-radius);

        &.validate {
            &.ng-valid.ng-dirty {
                border: 1px solid var(--confirmation-color);
            }

            &.ng-invalid.ng-dirty {
                border: 1px solid var(--warning-color);
            }
        }

        &:hover {
            border: 1px solid var(--general-border-color);
        }

        &:focus {
            border: 1px solid var(--general-text-inactive-color);
        }

        &:disabled {
            background: ~'rgba(var(--background-highlight-color-rgb), 0.5)';
            color: ~'rgba(var(--general-text-color), 0.5)';
            cursor: not-allowed;
        }

        &::placeholder {
            color: var(--general-text-color);
            opacity: .4;
        }
    }
}