.rl-message {
    .message-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .alert-icon {
        align-items: flex-start;
        min-width: 2rem;

        rl-icon {
            color: var(--alert-color);
        }
    }

    .warning-icon {
        align-items: center;
        min-width: 4rem;
        border-right: 1px solid #97443E;
    }

    .info-icon {
        align-items: flex-start;
        min-width: 2rem;

        rl-icon {
            color: var(--info-color);
        }
    }

    .message-text {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .alert-text, .info-text {
        color: var(--general-text-color);
    }

    .warning-text {
        padding: 0.5rem 1rem;
    }

    .title {
        display: flex;
    }

    .info-title {
        color: var(--general-text-color);
        display: flex;
        padding-bottom: 10px;
    }

    .close {
        color: var(--general-text-color) !important;
        opacity: .5;
        position: absolute;
        right: 15px;
        top: 65px;
    }
}

.rl-alert {
    display: flex;
    color: var(--button-text-color);
    font-weight: 400;
    font-size: 1rem;
    background: rgba(var(--alert-color-rgb), 0.2);
    border: none;
    padding: 1rem;
}

.rl-warning {
    display: flex;
    border-radius: var(--general-border-radius);
    color: var(--button-text-color);
    font-weight: 500;
    font-size: 1rem;
    background: var(--warning-color);
    border: 1px solid #97443E;
}

.rl-info {
    color: var(--button-text-color);
    font-weight: 400;
    font-size: 1rem;
    background: rgba(var(--info-color-rgb), 0.2);
    border-radius: var(--general-border-radius);
    padding: 1rem;
}