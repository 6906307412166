.no-content {
    height: 200px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -100px;

    i {
        display: block;
        margin: 0 auto 20px;
        text-align: center;
        font-size: 100px;
        padding: 0;
    }

    > span {
        display: block;
        margin: 0;
        text-align: center;
        font-weight: bold;
        font-size: 17px;
    }

    rl-icon {
        display: block;
        margin: 0;
        text-align: center;
        font-size: 64px;
        color: var(--general-text-inactive-color);
    }

    p {
        display: block;
        margin: 0 auto;
        padding: 0;
        max-width: 200px;
        text-align: center;
    }
}