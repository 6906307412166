@radius: 20px;

rl-progress {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
    }

    .bar {
        position: relative;
        width: 100%;
        height: 10px;
        background: rgba(var(--general-text-color-rgb), 0.2);
        backdrop-filter: blur(12px);

        border-radius: @radius;

        .inner {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;

            border-radius: @radius;
            background: var(--highlight-color);
        }
    }
}