rl-toggle-button {
    > div {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 10px;
        box-sizing: border-box;

        cursor: pointer;
        font-size: 13px;

        color: var(--general-text-color);
        border-radius: var(--general-border-radius);
        background-color: var(--general-background-color);
        line-height: 20px;

        &.active {
            font-weight: bold;
            color: var(--text-on-highlight-color);
            background: var(--highlight-color);
        }

        rl-icon {
            margin-left: 5px;
            font-size: 15px;
        }
    }
} 