.rl-large-headline {
    font-size: 28px;
    font-weight: 600;
    color: var(--general-text-color);
}

.rl-headline {
    font-size: 20px;
    font-weight: 600;
    color: var(--general-text-color);
}

.rl-headline-placeholder {
    height: 20px;
    border-radius: 4px;
    display: inline-block;
}

.rl-subheadline {
    font-size: 13px;
    color: var(--general-text-color);
    opacity: 0.4;
}

.rl-regular {
    font-size: 15px;
    font-weight: 400;
    color: var(--general-text-color);
}

.rl-small-text {
    font-size: 13px;
    font-weight: 400;
    color: var(--general-text-color);
}

.rl-upper {
    text-transform: uppercase;
}

.rl-bold {
    font-weight: 600;
}

.rl-link {
    color: var(--highlight-color);
    font-weight: 600;
}

.rl-label {
    font-size: 10px;
    font-weight: 600;
    color: var(--general-text-color);
}

.rl-label-inactive {
    font-size: 10px;
    font-weight: 400;
    color: var(--general-text-color);
    opacity: 0.4;
}