rl-phone-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

    rl-select {
        flex: 0 0 auto;
        width: 120px;

        .options {
            min-width: 300px;
            max-width: 100%;
        }

        &[disabled] {
            cursor: not-allowed;
            background: inherit;
            
            img {
                opacity: 0.5;
            }

            .select-area {
                color: var(--general-text-inactive-color);
                background-color: var(--locked-field-color);

                span {
                    opacity: 1;
                }
            }
        }
    }

    rl-text-input {
        flex: 1 1 auto;
        margin-left: 10px;

        input[disabled] {
            //overrides user-agent styles for ios devices
            opacity: 1;
            color: var(--general-text-inactive-color);
            background-color: var(--locked-field-color);
            border-color: transparent;
        }
    }

    rl-icon {
        position: absolute;
        right: var(--m-spacing);
        color: var(--general-text-inactive-color);
        top: 50%;
        transform: translate(0, -50%);
    }
}

html[dir="rtl"] {
    rl-phone-input {
        rl-text-input {
            margin-right: 10px;
            margin-left: 0;
        }
        
        rl-icon {
            left: 10px;
            right: unset;
        }
    }
}