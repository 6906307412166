rl-toggle-filters {
    position: relative;
    display: inline-block;
    height: 40px;

    .open-filter {
        padding: 10px;

        font-size: 13px;
        border-radius: var(--general-border-radius);
        background-color: var(--general-background-color);
        line-height: 20px;
        cursor: pointer;

        &.selected {
            background-color: var(--highlight-color);
            color: var(--text-on-highlight-color);

            rl-icon {
                color: var(--text-on-highlight-color);
            }
        }

        rl-icon {
            font-size: 12px;
            color: var(--general-text-inactive-color);
        }
    }
}

rl-toggle-filters-popup {
    action-sheet-custom .actionsheet {

        @media @desktop-up {
            margin-top: 50px;
            padding: 10px;
        }

        .actionsheet-content {
            padding-top: 0;
        }
    }

    @media @desktop-up {
        rl-select-list {
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 10px;

            .item {
                flex-direction: row-reverse;
                justify-content: flex-end;

                .title {
                    white-space: nowrap;
                }
                
                .icon {
                    display: none;
                }
            }
        }
    }
}