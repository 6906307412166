tip {
    .tip-content {
        z-index: 50;
        display: block;
        position: relative;
        padding: 10px 20px;
        margin-bottom: 25px;
        width: 220px;

        border-radius: 4px;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.6);
        color: var(--text-on-white-color);
        text-align: center;
        box-sizing: border-box;
        box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);

        transform: translateY(0);

        &.is-hidden {
            position: absolute;
        }

        h3 {
            margin: 0;
            font-size: 13px;
            line-height: 16px;
            font-weight: 600;
        }

        p {
            margin: 0;
            font-size: 13px;
            line-height: 16px;
        }

        &.hide {
            opacity: 0;
        }

        &:after {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -4px;
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 7.5px 0 7.5px;
            border-color: rgba(255, 255, 255, 0.6) transparent transparent transparent;
        }
    }

    &.top {
        .tip-content:after {
            top: -4px;
            border-width: 0 7.5px 4px 7.5px;
            border-color: transparent transparent rgba(255, 255, 255, 0.6) transparent;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 40;
    }
}