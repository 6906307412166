rl-translation-information {
    .translation-information {
        display: flex;
        flex-direction: column;
        padding: 15px;

        border-radius: var(--training-border-radius);
        background-color: rgba(var(--highlight-color-rgb), 0.2);
    }

    .translation-button {
        display: inline-flex;
        align-self: end;
        align-items: flex-end;

        gap: calc(var(--m-spacing) / 2);
        margin-top: var(--m-spacing);

        color: var(--highlight-color);

        > span {
            font-weight: 600;
        }
    }
}