.rl-animation-fade {
    transition: 0.3s linear opacity;

    &.ng-enter {
        opacity: 0;
    }

    &.ng-enter-active {
        opacity: 1;
    }

    &.ng-leave {
        opacity: 1;
    }

    &.ng-leave.ng-leave-active {
        opacity: 0;
    }

    &.ng-hide {
        opacity: 0;
    }
}

.rl-animation-height {
    transition: 0.3s ease-in-out max-height, 0.3s ease-in-out opacity;

    &.ng-enter {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }

    &.ng-enter-active {
        overflow: hidden;
        max-height: 500px;
        opacity: 1;
    }

    &.ng-leave {
        overflow: hidden;
        max-height: 500px;
        opacity: 1;
    }

    &.ng-leave.ng-leave-active {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }

    &.ng-hide {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }
}